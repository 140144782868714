import styled from 'styled-components';

const BreakpointsScrollSectionStyle = styled.section`
	--descriptionMarginTopBottom: var(--sp5x) auto var(--sp8x) auto;
	--descriptionMaxWidth: var(--sp193x);
	--snapScrollMarginTop: var(--sp13x);
	--swiperWidth: calc(var(--sp200x) + var(--sp44x));
	--borderRadius: var(--sp4x);
	--textWrapBottom: var(--sp7x);
	--titleMarginBottom: var(--sp3x);
	--descriptionMaxWidth: var(--sp156x);

	.text-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		.description {
			max-width: var(--descriptionMaxWidth);
			margin: var(--descriptionMarginTopBottom);
		}
	}

	.sticky-cont {
		position: sticky;
		top: 8%;
		overflow: hidden;
	}

	.snap-scroll-wrap {
		position: relative;
		display: flex;
		flex-direction: column;
		margin-left: auto;
		margin-right: auto;
		margin-top: var(--snapScrollMarginTop);
		width: var(--swiperWidth);
		padding-bottom: 52.5%;

		.file-wrap {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 200%;

			&.first {
				top: 0;
			}

			&.swiper-button-lock {
				display: unset !important;
			}
		}
	}

	.snap-file-inner {
		position: relative;

		.text-wrap {
			width: 100%;
			position: absolute;
			left: 50%;
			bottom: var(--textWrapBottom);
			transform: translateX(-50%);
			z-index: 2;

			.title {
				text-align: center;
				color: var(--backgroundLightColor);
				margin-bottom: var(--titleMarginBottom);
			}

			.description {
				text-align: center;
				color: var(--backgroundLightColor);
				max-width: var(--descriptionMaxWidth);
				margin: 0 auto;
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgb(0, 0, 0, 0.2);
			z-index: 2;
			border-radius: var(--borderRadius) !important;
		}
	}

	.snap-file {
		padding-top: 51.1%;

		img,
		video {
			border-radius: var(--borderRadius) !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--descriptionMarginTopBottom: var(--sp4x) auto var(--sp6x) auto;
		--descriptionMaxWidth: var(--sp145x);
		--snapScrollMarginTop: var(--sp10x);
		--swiperWidth: var(--sp183x);
		--borderRadius: var(--sp3x);
		--textWrapBottom: var(--sp5x);
		--titleMarginBottom: var(--sp2x);
		--descriptionMaxWidth: var(--sp107x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--descriptionMarginTopBottom: var(--sp3x) auto var(--sp5x) auto;
		--descriptionMaxWidth: var(--sp116x);
		--snapScrollMarginTop: var(--sp8x);
		--swiperWidth: var(--sp146x);
		--borderRadius: var(--sp2x);
		--textWrapBottom: var(--sp4x);
		--titleMarginBottom: var(--sp2x);
		--descriptionMaxWidth: var(--sp86x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--descriptionMarginTopBottom: var(--sp3x) auto var(--sp5x) auto;
		--descriptionMaxWidth: var(--sp106x);
		--snapScrollMarginTop: var(--sp8x);
		--swiperWidth: var(--sp133x);
		--borderRadius: var(--sp2x);
		--textWrapBottom: var(--sp4x);
		--titleMarginBottom: var(--sp2x);
		--descriptionMaxWidth: var(--sp86x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXS}) {
		--descriptionMarginTopBottom: var(--sp3x) auto var(--sp5x) auto;
		--descriptionMaxWidth: var(--sp103x);
		--snapScrollMarginTop: var(--sp7x);
		--swiperWidth: var(--sp130x);
		--borderRadius: var(--sp2x);
		--textWrapBottom: var(--sp5x);
		--titleMarginBottom: var(--sp2x);
		--descriptionMaxWidth: var(--sp86x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--descriptionMarginTopBottom: var(--sp2x) auto var(--sp4x) auto;
		--descriptionMaxWidth: var(--sp96x);
		--snapScrollMarginTop: var(--sp6x);
		--borderRadius: var(--sp2x);
		--textWrapBottom: var(--sp5x);
		--titleMarginBottom: var(--sp1x);
		--descriptionMaxWidth: var(--sp82x);

		.snap-file {
			margin-top: var(--sp6x);
			padding-top: 53%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--descriptionMarginTopBottom: var(--sp2x) auto var(--sp4x) auto;
		--descriptionMaxWidth: 100%;
		--snapScrollMarginTop: var(--sp6x);
		--swiperWidth: 100%;
		--borderRadius: var(--sp2x);
		--textWrapBottom: var(--sp5x);
		--textWrapBottom: var(--sp3x);
		--titleMarginBottom: 0;
		--descriptionMaxWidth: 100%;

		.snap-file {
			margin-top: var(--sp3x);
			padding-top: 53%;
		}

		.snap-file-inner {
			.description {
				display: none;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--descriptionMarginTopBottom: var(--sp2x) auto var(--sp3x) auto;
		--descriptionMaxWidth: 100%;
		--snapScrollMarginTop: var(--sp5x);
		--swiperWidth: 100%;
		--borderRadius: var(--sp1-5x);
		--textWrapBottom: var(--sp2x);
		--titleMarginBottom: 0;
		--descriptionMaxWidth: 100%;

		.snap-file {
			margin-top: var(--sp3x);
			padding-top: 53%;
		}

		.snap-file-inner {
			.description {
				display: none;
			}
		}
	}
`;

export default BreakpointsScrollSectionStyle;
