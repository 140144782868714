'use client';

import dynamic from 'next/dynamic';

//* Helpers
import { config } from '@/helpers';

//* Components
import Page from '@/components/global/Page';
import GloriaSection from './components/GloriaSection';
import HeroSection from '@/components/sections/HeroSection';
import BeforeAfterSection from '@/components/sections/BeforeAfterSection';
import BreakpointsScrollSection from '@/components/sections/BreakpointsScrollSection';

const FAQSection = dynamic(() => import('@/components/sections/FAQSection'));
const GallerySection = dynamic(() => import('@/components/sections/GallerySection'));
const ServicesListing = dynamic(() => import('../services/components/ServicesListing'));
const MouseHoverSection = dynamic(() => import('@/components/sections/MouseHoverSection'));
const NellySection = dynamic(() => import('../digital-practices/components/NellySection'));
const GetItTouchSection = dynamic(() => import('@/components/sections/GetItTouchSection'));

//* Style
import HomePageStyle from '@/styles/pages/HomePageStyle';

export default function HomeTemplate({ pageData }) {
	return (
		<Page className={'home'}>
			<HomePageStyle>
				<HeroSection
					isHome
					{...pageData.data.hero}
				/>

				<MouseHoverSection
					url={config.routes.philosophy.path}
					{...pageData.data.philosophy}
				/>

				<GloriaSection {...pageData.data.gloria} />

				<ServicesListing
					isHome
					title={pageData.data.service.title}
					description={pageData.data.service.description}
					data={pageData.data.service.services.slice(0, 8)}
				/>

				<BeforeAfterSection
					isHome
					{...pageData.data.before_after}
				/>

				<BreakpointsScrollSection {...pageData.data.digital_practice} />

				<NellySection isHome />

				<GallerySection {...pageData.data.gallery} />

				<GetItTouchSection isDark />

				<FAQSection />
			</HomePageStyle>
		</Page>
	);
}
