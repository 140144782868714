import styled from 'styled-components';

const GloriaSectionStyle = styled.section`
	--gloriaInfoContentPad: var(--sp8x);
	--titleMTop: var(--sp3x);
	--fileHeight: 100vh;

	padding-top: var(--sectionDistance);

	.gloria-section-wrapper {
		transform: translateY(100%) scale(1.2, 1.2);
	}

	.section-relative-wrapper {
		position: relative;
	}

	.section-absolute-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;

		> .container {
			height: 100%;
			overflow: hidden;

			> .row {
				height: 100%;
				padding: var(--gloriaInfoContentPad) 0;
			}
		}
	}

	.left-wrapper,
	.right-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.flex-end-wrapper {
		display: flex;
		justify-content: flex-end;
	}

	.gloria-title {
		margin-top: var(--titleMTop);
	}

	.image-cont,
	.video-cont {
		--proportion: var(--fileHeight);

		overflow: hidden;
		isolation: isolate;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 0.01%, rgba(0, 0, 0, 0) 10.5%, rgba(0, 0, 0, 0) 31.43%, rgba(0, 0, 0, 0) 68.72%, rgba(0, 0, 0, 0.15) 86.69%, rgba(5, 5, 5, 0.3) 100%);
			z-index: 2;
		}
	}

	.title-ref,
	.description-ref,
	.btn-ref {
		transform: translateY(300%);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--gloriaInfoContentPad: var(--sp6x);
		--titleMTop: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--gloriaInfoContentPad: var(--sp5x);
		--titleMTop: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--gloriaInfoContentPad: var(--sp5x);
		--titleMTop: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXS}) {
		--gloriaInfoContentPad: var(--sp5x);
		--titleMTop: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--gloriaInfoContentPad: var(--sp5x);
		--titleMTop: var(--sp1-5x);

		.gloria-section-wrapper {
			transform: translateY(0) scale(1, 1) !important;
		}

		.title-ref,
		.description-ref,
		.btn-ref {
			transform: translateY(0) !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--gloriaInfoContentPad: var(--sp6x);
		--titleMTop: var(--sp1-5x);

		.section-absolute-wrapper {
			> .container {
				> .row {
					flex-direction: column;
					justify-content: flex-end;
				}
			}
		}

		.flex-end-wrapper {
			justify-content: flex-start;

			.btn-wrap {
				margin-top: var(--sp4x);
			}
		}

		.gloria-section-wrapper {
			transform: translateY(0) scale(1, 1) !important;
		}

		.title-ref,
		.description-ref,
		.btn-ref {
			transform: translateY(0) !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--gloriaInfoContentPad: var(--sp2x);
		--titleMTop: var(--sp1x);
		--fileHeight: 144.93%;

		.section-absolute-wrapper {
			> .container {
				> .row {
					flex-direction: column;
					justify-content: flex-end;
				}
			}
		}

		.flex-end-wrapper {
			justify-content: flex-start;

			.btn-wrap {
				margin-top: var(--sp3x);
			}
		}

		.gloria-section-wrapper {
			transform: translateY(0) scale(1, 1) !important;
		}

		.title-ref,
		.description-ref,
		.btn-ref {
			transform: translateY(0) !important;
		}

		.image-cont,
		.video-cont {
			&:before {
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0.01%, rgba(0, 0, 0, 0) 10.5%, rgba(0, 0, 0, 0) 31.43%, rgba(0, 0, 0, 0) 47.98%, rgba(0, 0, 0, 0.2) 66.41%, rgba(5, 5, 5, 0.4) 100%);
			}
		}
	}
`;

export default GloriaSectionStyle;
