import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

//* HOC's
import { withUIContext } from '@/context';

//* Helpers
import { config, useActiveSection } from '@/helpers';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import CustomImage from '@/components/global/Image';
import CustomVideo from '@/components/global/Video';
import Container from '@/components/global/Container';

//* Style
import GloriaSectionStyle from './style';

const GloriaSection = ({ title, description, file, winWidth }) => {
	//! Refs
	const containerRef = useRef();
	const sectionRef = useRef();
	const titleRef = useRef();
	const descRef = useRef();
	const btnRef = useRef();

	//! Active Section
	const activeSection1 = useActiveSection(containerRef, 100);
	const activeSection2 = useActiveSection(containerRef, 0);

	//! Animation1
	useEffect(() => {
		if (winWidth >= 1366) {
			gsap.killTweensOf(sectionRef.current);

			if (activeSection1) {
				gsap.to(sectionRef.current, 1.3, {
					css: {
						transform: `translateY(0) scale(1, 1)`,
					},
					ease: 'easeOut',
				});
			} else {
				gsap.to(sectionRef.current, 1.7, {
					css: {
						transform: `translateY(100%) scale(1.2, 1.2)`,
					},
					ease: 'easeOut',
				});
			}
		}
	}, [activeSection1, winWidth]);

	//! Animation2
	useEffect(() => {
		if (winWidth >= 1366) {
			gsap.killTweensOf([titleRef.current, descRef.current, btnRef.current]);

			if (activeSection2) {
				//! Inner Anim
				gsap.to([titleRef.current, descRef.current, btnRef.current], {
					duration: 0.9,
					delay: 0.1,
					css: {
						transform: `translateY(0)`,
					},
					ease: 'easeOut',
				});
			} else {
				//! Inner Anim
				gsap.to([titleRef.current, descRef.current, btnRef.current], {
					duration: 0.9,
					delay: 0.1,
					css: {
						transform: `translateY(300%)`,
					},
					ease: 'easeOut',
				});
			}
		} else {
			//! Inner Anim
			gsap.set([titleRef.current, descRef.current, btnRef.current], {
				duration: 0,
				delay: 0,
				css: {
					transform: `translateY(0)`,
				},
			});
		}
	}, [activeSection2, winWidth]);

	return (
		<GloriaSectionStyle>
			<div
				ref={containerRef}
				className='gloria-section-container'>
				<div
					ref={sectionRef}
					className='gloria-section-wrapper'>
					<div className='section-relative-wrapper'>
						<div className='gloria-file-wrapper'>
							{file.type === 'image' ? (
								<CustomImage
									src={file.src}
									alt={file.alt}
								/>
							) : (
								<CustomVideo
									loop
									autoPlay
									src={file.src}
								/>
							)}
						</div>

						<div className='section-absolute-wrapper'>
							<Container row>
								<div className='left-wrapper col-7 col-ds-8 col-t-9 col-ts-12'>
									<div className='gloria-info-wrapper'>
										<Text
											ref={titleRef}
											className='h6 workSans-regular background-light-color description-ref'>
											{description}
										</Text>

										<Text
											ref={descRef}
											className='h2 workSans-regular background-light-color uppercase gloria-title title-ref'>
											{title}
										</Text>
									</div>
								</div>

								<div className='right-wrapper col-5 col-ds-4 col-t-3 col-ts-12'>
									<div className='flex-end-wrapper'>
										<div
											ref={btnRef}
											className='btn-ref'>
											<Button
												text={'readMore'}
												ariaLabel={'READ MORE'}
												url={config.routes.team.path}
												className='default secondary white-version'
											/>
										</div>
									</div>
								</div>
							</Container>
						</div>
					</div>
				</div>
			</div>
		</GloriaSectionStyle>
	);
};

export default withUIContext(GloriaSection, ['winWidth']);
