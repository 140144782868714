import { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import gsap from 'gsap';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';

//* HOC's
import { withUIContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '@/components/global/Text';
import Image from '@/components/global/Image';
import Video from '@/components/global/Video';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';

//* Style
import BreakpointsScrollSectionStyle from './style';

const BreakpointsScrollSection = ({ description, items, title, winWidth, winHeight }) => {
	//! States
	const [activeIndex, setActiveIndex] = useState(0);
	const [transformStep, setTransformStep] = useState(8);
	const [step, setStep] = useState(1);
	const [percentage, setPercentage] = useState(0);
	const [isPreventScroll, setIsPreventScroll] = useState(true);
	const [scrollDirectionSection, setScrollDirectionSection] = useState('down');

	//! Refs
	const filesRef = useRef([]);
	const containerRef = useRef();
	const heightCont = useRef();
	const stickyContRef = useRef();
	const isDisabled = useRef(false);

	useEffect(() => {
		if (winWidth >= 1366 && items.length > 2) {
			gsap.to(containerRef.current, { y: `${percentage}%` });
		}
	}, [percentage, winWidth, items]);

	useEffect(() => {
		if (winWidth >= 1366) {
			const { top } = heightCont?.current?.getBoundingClientRect();
			const scrollY = window.scrollY;

			const vhInPixels = 100 * (winHeight / 100);
			const elementStart = top + scrollY;

			if (top < 700) {
				window.scrollTo({ top: elementStart + activeIndex * vhInPixels - 100, behavior: 'smooth' });
			}
		}
	}, [activeIndex, winWidth, winHeight, scrollDirectionSection, items]);

	//! Items
	const storeItems = useMemo(() => {
		return items.map((item, index) => {
			return (
				<div
					key={index}
					style={{ zIndex: index + 1 }}
					ref={(el) => (filesRef.current[index] = el)}
					className={`file-wrap ${index === 0 ? 'first' : ''} ${index === activeIndex ? 'current' : index < activeIndex ? 'prev' : 'next'}`}>
					<div className='snap-file-inner'>
						{item.file.type === 'image' ? (
							<Image
								className={`snap-file snap-file-${index + 1}`}
								src={item.file.src}
								alt={item.file.alt}
							/>
						) : (
							<Video
								className={`snap-file snap-file-${index + 1}`}
								src={item.file.src}
								autoPlay={true}
								loop={true}
								controls={false}
							/>
						)}
						<div className='text-wrap'>
							<Text className={'h2 workSans-regular title'}>{item.title}</Text>
							<Text className={'p1 workSans-regular description'}>{item.description}</Text>
						</div>
					</div>
				</div>
			);
		});
	}, [items, activeIndex, winWidth]);

	const handleUp = useCallback(() => {
		if (winWidth >= 1366) {
			setScrollDirectionSection('up');

			if (activeIndex !== 0) {
				filesRef.current[activeIndex - 4] && gsap.to(filesRef.current[activeIndex - 4], { scale: 0.7 });
				filesRef.current[activeIndex - 3] && gsap.to(filesRef.current[activeIndex - 3], { scale: 0.8 });
				filesRef.current[activeIndex - 2] && gsap.to(filesRef.current[activeIndex - 2], { scale: 0.9 });
				filesRef.current[activeIndex - 1] && gsap.to(filesRef.current[activeIndex - 1], { scale: 1 });
				filesRef.current[activeIndex] && gsap.to(filesRef.current[activeIndex], { top: `${200 + transformStep}%` });

				setActiveIndex(activeIndex - 1);
				setTransformStep(transformStep - 8);
				setStep(step - 1);
				setPercentage(percentage + 7);
				setIsPreventScroll(true);
			}

			if (activeIndex === 1) {
				setIsPreventScroll(false);
			}
		}
	}, [activeIndex, items, winWidth, transformStep, step, percentage]);

	const handleDown = useCallback(() => {
		if (winWidth >= 1366) {
			setScrollDirectionSection('down');

			if (activeIndex !== items.length - 1) {
				filesRef.current[activeIndex - 3] && gsap.to(filesRef.current[activeIndex - 3], { scale: 1 - 0.4 });
				filesRef.current[activeIndex - 2] && gsap.to(filesRef.current[activeIndex - 2], { scale: 1 - 0.3 });
				filesRef.current[activeIndex - 1] && gsap.to(filesRef.current[activeIndex - 1], { scale: 1 - 0.2 });
				filesRef.current[activeIndex] && gsap.to(filesRef.current[activeIndex], { scale: 1 - 0.1 });
				filesRef.current[activeIndex + 1] && gsap.to(filesRef.current[activeIndex + 1], { top: `${transformStep}%` });

				setActiveIndex(activeIndex + 1);
				setTransformStep(transformStep + 8);
				setStep(step + 1);
				setPercentage(percentage - 7);
				setIsPreventScroll(true);
			}

			if (activeIndex === items.length - 1) {
				setIsPreventScroll(false);
			}
		}
	}, [activeIndex, items, winWidth, transformStep, step, percentage]);

	//! Stop scroll
	const handleScroll = useCallback(() => {
		if (winWidth >= 1366) {
			const { top, bottom, height } = heightCont.current.getBoundingClientRect();

			if (top <= 0 && bottom >= 0 && !isDisabled.current) {
				window.document.body.style.overflow = 'hidden';

				setTimeout(() => {
					window.document.body.style.overflow = '';
					isDisabled.current = true;
				}, 1000);
			}

			if (bottom >= height / 2) {
				isDisabled.current = false;
			}
		}
	}, [winWidth, items]);

	useEffect(() => {
		if (winWidth >= 1366) {
			window.addEventListener('scroll', handleScroll);

			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
	}, [handleScroll, winWidth, items]);
	//! Stop scroll

	return (
		<BreakpointsScrollSectionStyle>
			<Container isSection>
				<div className='text-content'>
					<Text
						className={'h6 workSans-medium'}
						text={title}
					/>

					<Text
						className={'h5 workSans-regular description'}
						text={description}
					/>

					<Button
						text={'digitalPractices'}
						className={'default secondary'}
						url={config.routes.digitalPractices.path}
					/>
				</div>
			</Container>

			{winWidth >= 1366 ? (
				<ReactScrollWheelHandler
					className='scroll-handler'
					preventScroll={isPreventScroll}
					upHandler={(e) => handleUp(e)}
					downHandler={(e) => handleDown(e)}>
					<div
						ref={heightCont}
						style={{ height: `${items.length * 100}vh` }}>
						<Container
							className={'sticky-cont'}
							ref={stickyContRef}>
							<div
								className={`snap-scroll-wrap`}
								ref={containerRef}>
								{storeItems}
							</div>
						</Container>
					</div>
				</ReactScrollWheelHandler>
			) : (
				<Container>
					<div className={`items-mobile`}>{storeItems}</div>
				</Container>
			)}
		</BreakpointsScrollSectionStyle>
	);
};

export default withUIContext(BreakpointsScrollSection, ['winWidth', 'winHeight']);
